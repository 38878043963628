<div *ngIf="expandedTableData?.length; else emptyTemplate" class="expanded-table">
  <div class="expanded-table__header expanded-gap">
    <p>Name</p>

    <p>Not Started</p>

    <p>In Progress</p>

    <p>Late</p>

    <p>Completed(Late)</p>

    <p>Expired</p>

    <p>Total</p>

    <!--    TODO - Hidden for current release -->
    <!--    <div-->
    <!--      class="expanded-table__header__notify"-->
    <!--      [matTooltip]="'Send a summary email with the inspection links to assignee'">-->
    <!--      Notify Again-->
    <!--    </div>-->
  </div>

  <div class="expanded-table__body">
    <mat-accordion *ngFor="let item of expandedTableData" [togglePosition]="'before'">
      <mat-expansion-panel
        (opened)="setExpandedStatus(item.propertyId, true)"
        (closed)="setExpandedStatus(item.propertyId, false)">
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">{{ item.expandedTitle }}</mat-panel-title>
        </mat-expansion-panel-header>

        <ng-container *ngTemplateOutlet="totalRaw; context: getContext(item, true)"></ng-container>

        <div *ngFor="let data of item.data" class="expanded-table__body__total-raw-table expanded-gap table-gray">
          <p>
            <app-fade-text [text]="data?.inspectionName ? data.inspectionName : '-'"></app-fade-text>
          </p>
          <p>{{ data.notStarted }}</p>
          <p>{{ data.inProgress }}</p>
          <p [style.color]="eColorPalette.cRed3">{{ data.late }}</p>
          <p>
            {{ getCompletedLate(data.completed, data.total) }} %
            <span [style.color]="eColorPalette.cRed3">({{ getCompletedLate(data.completedLate, data.total) }} %)</span>
          </p>

          <p>{{ data.expired }}</p>

          <p>{{ data.total }}</p>

          <!--    TODO - Hidden for current release -->
          <!--          <p>-->
          <!--            <app-icon-->
          <!--              [iconHeight]="20"-->
          <!--              [iconWidth]="32"-->
          <!--              [svg]="eIcon.MAIL2"-->
          <!--              [iconColor]="eColorPalette.cGreen3"-->
          <!--              [style.cursor]="'pointer'"></app-icon>-->
          <!--          </p>-->
        </div>
      </mat-expansion-panel>

      <ng-container
        *ngTemplateOutlet="
          !getExpandedStatus(item.propertyId) && totalRaw;
          context: getContext(item, false)
        "></ng-container>
    </mat-accordion>
  </div>
</div>

<ng-template #emptyTemplate>
  <div class="display-flex justify-content-center">
    <div class="image-container">
      <img width="284" height="284" src="assets/empty-dashboard.png" alt="" />
      <div class="image-description text-center">No data to display.</div>
    </div>
  </div>
</ng-template>

<ng-template
  #totalRaw
  let-showTotal="showTotal"
  let-notStarted="notStarted"
  let-expandedTitle="expandedTitle"
  let-completed="completed"
  let-completedLate="completedLate"
  let-inProgress="inProgress"
  let-late="late"
  let-expired="expired"
  let-total="total">
  <div class="expanded-table__body__total-raw expanded-gap">
    <p>{{ showTotal ? 'Total' : '' }}</p>
    <p>{{ notStarted }}</p>
    <p>{{ inProgress }}</p>
    <p [style.color]="eColorPalette.cRed3">{{ late }}</p>
    <p>
      {{ getCompletedLate(completed, total) }}%
      <span [style.color]="eColorPalette.cRed3">({{ getCompletedLate(completedLate, total) }} %)</span>
    </p>
    <p>{{ expired }}</p>
    <p>{{ total }}</p>
    <!--    TODO - Hidden for current release -->
    <!--    <p></p>-->
  </div>
</ng-template>
