import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { find, isEmpty, isNil, isUndefined } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { EIcon } from '@shared/enums/icon.enum';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { ValueLabelPair } from '@shared/interfaces/value-label-pair.interface';
import { DestroyService } from '@shared/services/destroy.service';
import { LocalCacheService } from '@shared/services/local-cache.service';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';
import { skipEqual$ } from '@shared/utils/rxjs/skip-equal.rxjs.util';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-radio-button-dropdown',
  templateUrl: './radio-button-dropdown.component.html',
  styleUrls: ['./radio-button-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DestroyService],
})
export class RadioButtonDropdownComponent<T>
  extends CustomControlAbstract<any>
  implements OnInit, DoCheck, AfterViewInit, ControlValueAccessor, AfterContentChecked
{
  @Input() valueCss = '';
  @Input() optionCss = '';
  @Input() labelCss = '';
  @Input() containerWidthCss = '';
  @Input() containerCss = '';
  @Input() ngSelectCss = '';
  @Input() label = '';
  @Input() labelTooltip = '';
  @Input() itemPlaceholder = 'item';
  @Input() tooltipShowDelay: number = null;
  @Input() icon: EIcon;
  @Input() iconTooltip = '';
  @Input() attrPlaceholder = '';
  @Input() filterGroupKey?: string;
  @Input() hasFade = true;
  @Input() onSelectValueContent: ValueLabelPair;
  // temporary for deprecated values (see MoveOutType)
  @Input() unsupportedValueLabel = 'select value';
  /*
   * issue available https://github.com/ng-select/ng-select/issues/2055
   * known workaround - https://github.com/Kyotu-Technology/noetic-frontend/pull/455
   */
  @Input() highlightWhenActive = false;
  @Input() attrAppendTo = '';
  @Input() allowSelectAll = false;
  @Input() isSmallFont = false;
  @Input() isSmallPadding = false;
  @Input() multiSelect = false;
  @Input() attrDisable = false;
  @Input() isLabelInside = false;
  @Input() markAsInvalid = false;
  @Input() allowClear = true;
  @Input() allowSearch = true;
  @Input() displayLabelAsValue = false;
  @Input() labelRequired: boolean;
  @Input() showEmptyValue = false;
  @Input() showValueTooltip = false;
  @Input() activeOnFocus = false;
  @Input() errorSection = true;
  // TODO: move autoSortList to setter
  // otherwise parameters order matters
  @Input() autoSortList = true;
  @Input() dividerPosition!: number;
  @Input() allPropertiesLabel = false;
  @Input() displaySkeleton = false;
  @Input() scrollableElementId: string;
  @Input() scrollableElementIds: string[];
  @Input() allowGreaterThan = false;
  @Input() allPropertiesPlaceholder!: string;

  @Input() set items(items: IRadioButtonOption<T>[] | IRadioButtonOption<T>[][]) {
    this._items = items;
    this.controlSectionItems = [];
    this.controlItems = [];
    this.transformValueList = [];

    if (Array.isArray(items?.[0])) {
      this.controlSectionItems = (items as IRadioButtonOption<T>[][]).map(section => this.sortSection(section));
      this.transformValueList = this.controlSectionItems.flat();
    } else {
      this.controlItems = this.sortSection(items as IRadioButtonOption<T>[]);
      this.transformValueList = [...this.controlItems];
    }
    this.transformValue();
  }

  @Input() set listLoading(listLoading: boolean) {
    this._listLoading = listLoading;
    this.cdr.markForCheck();
  }

  @Input() set onOpenSelector(openSelector: boolean) {
    setTimeout(() => {
      if (openSelector && this.ngSelect) this.ngSelect.open();
    });
  }

  @Input() customValueTemplate: TemplateRef<any>;
  @Input() customListItemTemplate?: TemplateRef<any>;
  @Input() allowAddNew = false;
  @Input() addNewLabel = '+ Add new item';
  @Output() addNewClicked = new EventEmitter();

  displayValue = '';
  controlItems: IRadioButtonOption<T>[] = [];
  controlSectionItems: IRadioButtonOption<T>[][] = [];
  transformValueList: IRadioButtonOption<T>[] = [];
  hasValue = false;
  _labelWidth$ = new BehaviorSubject<number>(null);
  labelWidth$ = this._labelWidth$.asObservable();
  paddingLeft$!: Observable<string>;
  private _items: IRadioButtonOption<T>[] | IRadioButtonOption<T>[][] = [];
  _listLoading = false;
  labelWidthN: number;
  _labelElement: ElementRef<HTMLElement>;
  _focused = false;
  isOpen = false;

  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  @ViewChild('labelElement', { static: false }) set content(value: ElementRef<HTMLElement>) {
    if (!value) return;

    const labelWidth = value?.nativeElement?.clientWidth;
    labelWidth && this._labelWidth$.next(labelWidth);

    this._labelElement = value;

    this.cdr.detectChanges();
  }

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    @Optional() formDirective: FormGroupDirective,
    private cache: LocalCacheService,
    private destroy$: DestroyService
  ) {
    super(ngControl, cdr, formDirective);
  }

  ngDoCheck(): void {
    // TODO find out where do we changing controls with this way. Remove occurencies and remove DoCheck from here.
    //It's obligatory as we can change reactive control that is binding with this control
    if (this.ngControl?.control && this.control !== this.ngControl.control) {
      this.initControl();
    }
  }

  ngAfterContentChecked(): void {
    if (!this.isLabelInside || !this._labelElement) return;

    this.labelWidthN = this._labelElement?.nativeElement?.clientWidth;
    const width = this._labelElement?.nativeElement?.clientWidth;
    width && this._labelWidth$.next(width);

    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.initControl();
    if (!this.autoSortList) this.unsortItems();
  }

  ngAfterViewInit() {
    this.initPaddingLeft();
  }

  get isShowDisplayValue() {
    if (!this.allowSearch) return true;
    return !this._focused;
  }

  searchFunction(term: string, value: T): boolean {
    term = term.toLowerCase();
    const item = find(this.transformValueList, { value }) as IRadioButtonOption<T>;
    return item?.label?.toLowerCase()?.indexOf(term) > -1;
  }

  writeValue(value: any): void {
    if (value != this.control.value) {
      this.control.setValue(value);
    }
    this.transformValue();
  }

  onSelectAll() {
    this.control.patchValue(this.transformValueList.map(t => t.value));
    this.cdr.detectChanges();
  }

  onClearAll() {
    this.control.patchValue([]);
    this.cdr.detectChanges();
  }

  onAddNew() {
    this.addNewClicked.emit();
    this.ngSelect.close();
    this.cdr.detectChanges();
  }

  onClear() {
    this.transformValue();
  }

  onOpen() {
    if (this.multiSelect) {
      if (this.controlSectionItems?.length) {
        this.items = this.controlSectionItems;
      } else if (this.controlItems?.length) {
        this.items = this.controlItems;
      }
    }

    if (this.scrollableElementIds?.length) this.toggleScrollableElementScroll('hidden', '5.4px');
  }

  onClose(): void {
    if (this.scrollableElementIds?.length) this.toggleScrollableElementScroll('auto', '0px');
  }

  handleDropdownClick($event: MouseEvent): void {
    const target = $event.target as HTMLElement;
    if (target.tagName === 'NG-SELECT') {
      this.ngSelect.toggle();
    }
  }

  compareFn(item: unknown, selected: unknown): boolean {
    if (Array.isArray(selected)) {
      return selected.includes(item);
    } else {
      return selected === item;
    }
  }

  private sortSection(items: IRadioButtonOption<T>[]) {
    return items?.length && this.autoSortList
      ? [...items].sort((a, b) => {
          if (this.multiSelect) {
            const aSelected = this.control.value?.includes(a.value);
            const bSelected = this.control.value?.includes(b.value);
            if (aSelected != bSelected) {
              return aSelected < bSelected ? 1 : -1;
            }
          }
          if (a.sort != b.sort) {
            if (typeof b.sort === 'undefined') return -1;
            if (typeof a.sort === 'undefined') return 1;
            return a.sort > b.sort ? 1 : -1;
          }
          return a.label.localeCompare(b.label, undefined, { sensitivity: 'accent' });
        })
      : items || [];
  }

  private initControl() {
    this.initControlBase();

    this.transformValue();
    this.control.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => this.transformValue());
  }

  private unsortItems() {
    if (this.items && !this.autoSortList) {
      this.controlItems = this._items as IRadioButtonOption<T>[];
      this.transformValueList = [...this.controlItems];
    } else {
      this.controlItems = this.sortSection(this._items as IRadioButtonOption<T>[]);
      this.transformValueList = [...this.controlItems];
    }

    this.transformValue();
  }

  private transformValue() {
    const value = this.control?.value;
    const hasValue = Array.isArray(value) ? !isEmpty(value) : !isNil(value);
    if (hasValue || this.showEmptyValue) {
      this.hasValue = true;
      if (Array.isArray(value)) {
        if (this.displayLabelAsValue) {
          this.displayValue = this.transformValueList
            .filter(x => value.includes(x.value))
            .map(x => x.label)
            ?.join(', ');
        } else {
          if (this.allPropertiesLabel && value.length === this.controlItems?.length) {
            this.displayValue = 'All Properties';
          } else if (value.length > 1) {
            this.displayValue = this.onSelectValueContent
              ? this.getCustomTransformValue(value.length)
              : `${value.length} ${this.pluralizeWord(this.itemPlaceholder, value.length)}`;
          } else {
            const displayValue = this.transformValueList?.find(item => item.value === value[0])?.label;
            this.displayValue = isUndefined(displayValue) ? this.unsupportedValueLabel : displayValue;
          }
        }
      } else {
        const valueStr = JSON.stringify(value);
        const item = this.transformValueList?.find(
          item => item.value === value || JSON.stringify(item.value) === valueStr
        );
        let displayValue = item?.displayValue || item?.label;

        if (this.allPropertiesPlaceholder && valueStr === '-1') displayValue = this.allPropertiesPlaceholder;

        this.displayValue = isUndefined(displayValue) ? this.unsupportedValueLabel : displayValue;
      }
    } else {
      this.hasValue = false;
    }

    this.cdr.detectChanges();
  }

  private pluralizeWord(word: string, count: number): string {
    if (count > 1) {
      if (word.endsWith('y')) {
        return word.slice(0, -1) + 'ies';
      } else if (
        word.endsWith('s') ||
        word.endsWith('x') ||
        word.endsWith('z') ||
        word.endsWith('ch') ||
        word.endsWith('sh')
      ) {
        return word + 'es';
      } else if (word.endsWith('f')) {
        return word.slice(0, -1) + 'ves';
      } else if (word.endsWith('fe')) {
        return word.slice(0, -2) + 'ves';
      } else if (word.endsWith('o') && !/(photo|piano|halo)$/.test(word)) {
        return word + 'es';
      } else if (word.endsWith('us')) {
        return word.slice(0, -2) + 'i';
      } else {
        return word + 's';
      }
    }
    return word;
  }

  private getCustomTransformValue(count: number): string {
    if (!count) {
      return '';
    }

    return (count > 1 ? this.onSelectValueContent.multiple : this.onSelectValueContent.single).replace(
      this.onSelectValueContent.mask,
      `${count}`
    );
  }

  private initPaddingLeft(): void {
    const labelWidth$ = this.labelWidth$.pipe(
      filterNullish$(false),
      skipEqual$(),
      tap(currentLabelWidth => {
        if (this.filterGroupKey) {
          const groupOffset = this.cache.get(this.filterGroupKey);
          if ((groupOffset as number) < currentLabelWidth) {
            this.cache.set(this.filterGroupKey, currentLabelWidth, {
              lsKey: this.filterGroupKey,
            });
          }
        }
      })
    );

    const labelGroupWidth$ = this.filterGroupKey
      ? this.cache
          .get$(this.filterGroupKey, this.destroy$, {
            initialState: 0,
            lsKey: this.filterGroupKey,
          })
          .pipe(skipEqual$())
      : of(null);

    this.paddingLeft$ = combineLatest([labelWidth$, labelGroupWidth$]).pipe(
      map(([labelWidth, groupWidth]) => Math.max(labelWidth, groupWidth)),
      filterNullish$(false),
      map(width => (this.isLabelInside && width ? width + 'px' : 'initial')),
      skipEqual$()
    );
  }

  private toggleScrollableElementScroll(overflowY: string, marginRight: string): void {
    this.scrollableElementIds.forEach(id => {
      const viewport = document.getElementById(id) as HTMLElement;
      if (viewport && (overflowY === 'auto' || viewport.scrollHeight > viewport.clientHeight)) {
        viewport.style.overflowY = overflowY;
        viewport.style.marginRight = marginRight;
      }
    });
  }
}
