import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LineController,
  LinearScale,
  PieController,
  SubTitle,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { ChartTypes } from '@dashboards/models/chart-types.enum';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { ChartConfig, ChartDataItem } from '@shared/interfaces/chart-data-item';
import { ChartService } from '@ui-components/components/chart/services/chart.service';

Chart.register(
  ArcElement,
  BarElement,
  BarController,
  LineController,
  PieController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  SubTitle,
  ChartDataLabels
);

Chart.defaults.set('plugins.datalabels', {
  display: false,
});

@UntilDestroy()
@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent extends ComponentAbstract implements OnInit {
  id = `chart_${new Date().getTime()}`;
  _canvas: ElementRef;
  chart: Chart;
  dataListBSubject: BehaviorSubject<ChartDataItem[]> = new BehaviorSubject<ChartDataItem[]>([]);
  canvasBSubject: BehaviorSubject<ElementRef> = new BehaviorSubject<ElementRef>(null);
  _chartDataList: ChartDataItem[] = [];
  itemCount = 0;
  dataSum = 0;
  activeLegend: { label: string; value: number } | null = null;

  previousCanvas = null;

  @Input() width = 100;
  @Input() height = 100;
  @Input() chartType = ChartTypes.PIE;
  @Input() displayLegend = true;
  @Input() displayLegendBottom = false;
  @Input() displayLegendInside = false;
  @Input() emptyTemplate: TemplateRef<any>;
  @Input() emptyChartLabel = 'No data to display';
  @Input() chartConfig: ChartConfig = {
    showTooltip: true,
    showDataLabel: true,
    cutoutLevel: 70,
  };
  @Input() displayPercentageInLegend = false;

  @Input() containerCss = '';
  @Input() legendCss = 'body-description';

  @Input() clickableLabel = false;
  @Input() labelActiveColor?: EColorPalette;
  @Input() labelBlurColor?: EColorPalette;

  @Input() chartMaxWidth?: string;

  @Input() set chartDataList(chartDataList: ChartDataItem[]) {
    this.itemCount = chartDataList?.length;
    this.dataListBSubject.next(chartDataList);

    this.cdr.detectChanges();
  }

  @ViewChild('canvas', { static: false }) set content(canvas: ElementRef) {
    this.canvasBSubject.next(canvas);

    this.cdr.detectChanges();
  }

  constructor(protected cdr: ChangeDetectorRef, private renderer: Renderer2, private chartService: ChartService) {
    super(cdr);
  }

  ngOnInit(): void {
    combineLatest([this.dataListBSubject, this.canvasBSubject])
      .pipe(
        untilDestroyed(this),
        filter(([chartDataList, canvas]) => !!chartDataList?.length && !!canvas),
        tap(([chartDataList, canvas]) => {
          this._chartDataList = chartDataList;

          if (this.displayLegendInside && !this.activeLegend && chartDataList.length > 0) {
            const largestValueItem = chartDataList.reduce((maxItem, currentItem) => {
              return currentItem.value > (maxItem?.value || 0) ? currentItem : maxItem;
            }, null);

            if (largestValueItem) {
              this.activeLegend = { label: largestValueItem.label, value: largestValueItem.value };
            }
          }

          this.chartService.getChartData(chartDataList);
          this.dataSum = this.chartService.getDataSum();
          this.chart = this.createChart(canvas);

          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  showActiveElement(index) {
    if (this.chart) {
      this.chart.setActiveElements([{ datasetIndex: 0, index }]);
      this.chart.update();
    }
  }

  reset() {
    if (this.chart) {
      this.chart.setActiveElements([]);
    }
  }

  changeActiveSelection(item: ChartDataItem): void {
    if (this.clickableLabel && item.value > 0) {
      if (this.displayLegendInside) this.activeLegend = { label: item.label, value: item.value };
      const formattedChartData = this._chartDataList.map(a => ({
        ...a,
        backgroundColor: !a.value
          ? EColorPalette.cGray8
          : item.value && a.label === item.label
          ? this.labelActiveColor
          : this.labelBlurColor,
      }));

      this.dataListBSubject.next(formattedChartData);
    }
  }

  private createChart(canvas: ElementRef): Chart {
    this.destroyChart();
    return new Chart(
      canvas.nativeElement,
      this.chartService.getChartConfig(this.chartType, this._chartDataList, this.chartConfig)
    );
  }

  private destroyChart() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
