import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

import { procentCut } from '@app/utils/procent-cut';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import {
  SUMMARY_TABLE_COLUMNS_RECORD,
  SUMMARY_TABLE_COLUMNS_RECORD_SPECIAL,
  SUMMARY_TOTAL_EMPTY,
} from '@ui-components/modals/inspections-summary-report-modal/components/summary-table/summary-table.constant';
import { SELECT_FILTER_SHOW_BY_CONFIG } from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.constant';
import {
  ISelectFilterConfig,
  InspectionSummaryShowByRecord,
  InspectionSummaryTableDTO,
} from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.model';

@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrls: ['./summary-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryTableComponent implements AfterViewInit {
  @Input() set tableData(value: InspectionSummaryTableDTO[]) {
    if (value?.length) {
      this.dataSource = new MatTableDataSource(value);

      this.dataSource.sort = this.sort;

      this.totals = value.reduce(
        (acc, val) => {
          acc.not_started += val.not_started || 0;
          acc.in_progress += val.in_progress || 0;
          acc.late += val.late || 0;
          acc.completed += +val.completed || 0;
          acc.completed_late += +val.completed_late || 0;
          acc.total += val.total || 0;
          return acc;
        },
        {
          ...SUMMARY_TOTAL_EMPTY,
        }
      );
      this.totals.completed_procent = procentCut((this.totals.completed / this.totals.total) * 100);
      this.totals.completed_late_procent = procentCut((this.totals.completed_late / this.totals.total) * 100);
    }
  }

  @Input() showByType: ISelectFilterConfig = SELECT_FILTER_SHOW_BY_CONFIG[1];

  totals = { ...SUMMARY_TOTAL_EMPTY };

  readonly summaryTableColumnsRecord = { ...SUMMARY_TABLE_COLUMNS_RECORD_SPECIAL, ...SUMMARY_TABLE_COLUMNS_RECORD };
  readonly inspectionSummaryShowByRecord = InspectionSummaryShowByRecord;

  readonly eIcon = EIcon;
  readonly eColorPalette = EColorPalette;

  isAnimationDisabled$ = new BehaviorSubject(true);

  dataSource: MatTableDataSource<InspectionSummaryTableDTO> = new MatTableDataSource([]);

  columns = [SUMMARY_TABLE_COLUMNS_RECORD_SPECIAL.template, ...Object.keys(SUMMARY_TABLE_COLUMNS_RECORD)];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private _liveAnnouncer: LiveAnnouncer) {}

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
}
