export const SUMMARY_TABLE_COLUMNS_RECORD = {
  not_started: 'not_started',
  in_progress: 'in_progress',
  late: 'late',
  expired: 'expired',
  completed_late: 'completed_late',
  total: 'total',
  // TODO - Hidden for current release
  // notify_again: 'notify_again',
};

export const SUMMARY_TABLE_COLUMNS_RECORD_SPECIAL = {
  name: 'name',
  template: 'template',
};

export const SUMMARY_TOTAL_EMPTY = {
  not_started: 0,
  in_progress: 0,
  late: 0,
  completed: 0,
  completed_procent: 0,
  completed_late: 0,
  completed_late_procent: 0,
  expired: 0,
  total: 0,
};
