<ng-container *ngIf="dataSource?.data?.length">
  <div class="w-100 display-flex flex-1 w-100">
    <div class="summary-table-wrapper">
      <table mat-table matSort [@.disabled]="isAnimationDisabled$ | async" [dataSource]="dataSource">
        <ng-container [matColumnDef]="summaryTableColumnsRecord.template">
          <th *matHeaderCellDef mat-sort-header mat-header-cell>
            <div class="header-cell-block">
              <p class="header-cell header-cell-base">
                {{ showByType.label.toLowerCase() === inspectionSummaryShowByRecord.assignee ? 'Name' : 'Template' }}
              </p>

              <p class="header-cell">Total</p>
            </div>
          </th>

          <td *matCellDef="let summary" mat-cell class="body-cell">
            <app-fade-text [text]="summary.template"></app-fade-text>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="summaryTableColumnsRecord.not_started">
          <th *matHeaderCellDef mat-sort-header mat-header-cell #sortHeader="matSortHeader">
            <div class="header-cell-block">
              <p class="header-cell header-cell-base">Not Started</p>

              <p class="header-cell">{{ totals.not_started }}</p>
            </div>
          </th>

          <td *matCellDef="let summary" mat-cell class="body-cell">
            {{ summary.not_started }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="summaryTableColumnsRecord.in_progress">
          <th *matHeaderCellDef mat-sort-header mat-header-cell class="header-cell">
            <div class="header-cell-block">
              <p class="header-cell header-cell-base">In Progress</p>

              <p class="header-cell">{{ totals.in_progress }}</p>
            </div>
          </th>

          <td *matCellDef="let summary" mat-cell class="body-cell">
            {{ summary.in_progress }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="summaryTableColumnsRecord.late">
          <th *matHeaderCellDef mat-sort-header mat-header-cell class="header-cell">
            <div class="header-cell-block">
              <p class="header-cell header-cell-base">Late</p>

              <p class="header-cell body-cell-late">{{ totals.late }}</p>
            </div>
          </th>

          <td *matCellDef="let summary" mat-cell class="body-cell-late body-cell">
            {{ summary.late }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="summaryTableColumnsRecord.completed_late">
          <th *matHeaderCellDef mat-sort-header mat-header-cell class="header-cell">
            <div class="header-cell-block">
              <p class="header-cell header-cell-base">Completed (Late)</p>

              <p class="header-cell">
                {{ totals.completed_procent }}%
                <span class="body-cell-late">({{ totals.completed_late_procent }}%)</span>
              </p>
            </div>
          </th>

          <td *matCellDef="let summary" mat-cell class="body-cell completed-late">
            <span>{{ summary.completedProcent }} %</span>
            <span>({{ summary.completedLateProcent }} %)</span>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="summaryTableColumnsRecord.expired">
          <th *matHeaderCellDef mat-sort-header mat-header-cell class="header-cell">
            <div class="header-cell-block">
              <p class="header-cell header-cell-base">Expired</p>

              <p class="header-cell">
                {{ totals.expired }}
              </p>
            </div>
          </th>

          <td *matCellDef="let summary" mat-cell class="body-cell completed-late">
            {{ summary.expired }}
          </td>
        </ng-container>

        <ng-container [matColumnDef]="summaryTableColumnsRecord.total">
          <th *matHeaderCellDef mat-sort-header mat-header-cell class="header-cell">
            <div class="header-cell-block">
              <p class="header-cell header-cell-base">Total</p>

              <p class="header-cell">{{ totals.total }}</p>
            </div>
          </th>

          <td *matCellDef="let summary" mat-cell class="body-cell">
            {{ summary.total }}
          </td>
        </ng-container>

        <!--    TODO - Hidden for current release -->
        <!--        <ng-container [matColumnDef]="summaryTableColumnsRecord.notify_again">-->
        <!--          <th *matHeaderCellDef mat-header-cell class="body-cell-notify">-->
        <!--              <div [matTooltip]="'Send a summary email with the inspection links to assignee'">Notify Again</div>-->
        <!--          </th>-->
        <!--          <td *matCellDef="let summary" mat-cell class="body-cell">-->
        <!--              <app-icon-->
        <!--                [iconHeight]="20"-->
        <!--                [iconWidth]="32"-->
        <!--                [svg]="eIcon.MAIL2"-->
        <!--                [iconColor]="eColorPalette.cGreen3"-->
        <!--                [style.cursor]="'pointer'"></app-icon>-->
        <!--          </td>-->
        <!--        </ng-container>-->

        <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columns" mat-row></tr>
        <tr
          *matNoDataRow
          class="mat-row"
          app-table-empty-content
          [attrColspan]="2"
          [message]="'No inspections summary'"></tr>
      </table>
    </div>
  </div>
</ng-container>
