<div class="summary-report-modal">
  <ng-container *ngIf="!isLoading; else uploadingSpinner">
    <form [formGroup]="form" class="h-100">
      <div class="summary-report-modal__header">
        <div class="summary-report-modal__header__title">
          <h5>Inspections Summary Report</h5>

          <app-icon
            class="summary-icon cursor-pointer"
            [svg]="eIcon.DOWNLOAD_ICON"
            [iconColor]="eColorPalette.cGray5"
            [iconWidth]="20"
            [iconHeight]="20"
            (click)="onDownloadXlsFile()"></app-icon>
        </div>

        <div class="summary-report-modal__header__group">
          <p>Group by Property</p>

          <app-slide-toggle formControlName="groupByProperty"></app-slide-toggle>
        </div>
      </div>

      <div class="summary-report-modal__filter">
        <div class="summary-report-modal__filter__period">
          <p>Period:</p>

          <span *ngIf="activeStartDate && activeEndDate">
            {{ activeStartDate.format('MM/DD/YYYY') }} - {{ activeEndDate.format('MM/DD/YYYY') }}
          </span>

          <app-select-filter
            [showBrackets]="periodControl.value.id !== 6"
            [selectedFilter]="periodControl.value"
            [selectFiltersConfig]="selectPeriodFiltersConfig"
            (selectedItemEvent)="selectedPeriod($event)"></app-select-filter>

          <div [matMenuTriggerFor]="menu" [matMenuTriggerRestoreFocus]="true"></div>

          <mat-menu #menu>
            <div
              class="calendar-wrapper"
              (click)="$event.stopPropagation()"
              (keydown.tab)="$event.stopPropagation()"
              (keydown.shift.tab)="$event.stopPropagation()">
              <mat-calendar
                [headerComponent]="header"
                [selected]="dateRangeConfig"
                (selectedChange)="onSelect($event)"></mat-calendar>
            </div>
          </mat-menu>
        </div>

        <div>
          <p>Show By:</p>

          <div [style.margin-top]="data.isResident ? '0' : '1px'">
            <ng-container [ngTemplateOutlet]="data.isResident ? showByType : selectShowByFilter"></ng-container>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isDataLoaded; else emptyDashboard">
        <div class="summary-report-modal__body">
          <div class="summary-report-modal__body__table">
            <ng-container
              [ngTemplateOutlet]="groupByPropertyControl.value ? expandedSummaryTable : summaryTable"></ng-container>

            <ng-template #summaryTable>
              <app-summary-table
                [tableData]="summaryReportTableData | async"
                [showByType]="showByTypeControl.value"></app-summary-table>
            </ng-template>

            <ng-template #expandedSummaryTable>
              <app-summary-expanded-table
                [summaryExpandedTableData]="summaryReportTableExpandedData | async"></app-summary-expanded-table>
            </ng-template>
          </div>
          <div class="summary-report-modal__body__chart">
            <app-summary-chart [summaryChartData]="(summaryReportChartData | async) || []"></app-summary-chart>
          </div>
        </div>
      </ng-container>
    </form>
  </ng-container>
</div>

<ng-template #uploadingSpinner>
  <div class="summary-report-modal__uploadingSpinnerWrapper">
    <div class="display-flex align-items-center justify-content-center upload-spinner-container">
      <div class="upload-template-spinner">
        <mat-spinner class="spinner-border" diameter="80"></mat-spinner>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyDashboard>
  <div class="empty-dashbpard">
    <img width="284" height="284" src="assets/empty-dashboard.png" alt="" />
    <div class="image-description">{{ emptyDashboardMessage }}</div>
  </div>
</ng-template>

<ng-template #showByType>
  <p class="showTypeTitle">Type</p>
</ng-template>

<ng-template #selectShowByFilter>
  <app-select-filter
    [selectedFilter]="showByTypeControl.value"
    [selectFiltersConfig]="selectShowByFiltersConfig"
    (selectedItemEvent)="selectedShowBy($event)"></app-select-filter>
</ng-template>
