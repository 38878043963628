import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { procentCut } from '@app/utils/procent-cut';
import {
  InspectionSummaryExpandedTableData,
  InspectionSummaryTableDataTotal,
} from '@main-application/inspections/models/inspection-summary.model';
import { EColorPalette } from '@shared/enums/color-palette.enum';

@Component({
  selector: 'app-summary-expanded-table',
  templateUrl: './summary-expanded-table.component.html',
  styleUrls: ['./summary-expanded-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryExpandedTableComponent {
  @Input() set summaryExpandedTableData(value: InspectionSummaryExpandedTableData[]) {
    this.expandedTableData = value;

    this.expandedStatusRecord = value.map(a => ({ propertyId: a.propertyId, opened: false }));
  }

  expandedTableData: InspectionSummaryExpandedTableData[] = [];
  expandedStatusRecord = this.expandedTableData.map(a => ({ propertyId: a.propertyId, opened: false }));

  readonly eColorPalette = EColorPalette;

  getExpandedStatus(propertyId: number): boolean {
    return this.expandedStatusRecord.find(a => a.propertyId === propertyId)?.opened;
  }

  setExpandedStatus(propertyId: number, status: boolean): void {
    const record = this.expandedStatusRecord.find(a => a.propertyId === propertyId);
    if (record) {
      record.opened = status;
    }
  }

  getContext(
    item: InspectionSummaryExpandedTableData,
    showTotal: boolean
  ): { showTotal: boolean } & InspectionSummaryTableDataTotal {
    return { showTotal, ...item.total };
  }

  getCompletedLate(value: number, total: number): number {
    return procentCut((value / total) * 100);
  }
}
