export interface InspectionsSummaryReportModalPeriod {
  startDate: string | null;
  endDate: string | null;
}

export interface ISelectFilterConfig {
  id: number;
  label: string;
}

export interface InspectionSummaryTableDTO {
  template: string;
  not_started: number;
  in_progress: number;
  late: number;
  completed: number;
  completed_late: number;
  expired: number;
  total: number;
  completedLateProcent: number;
  completedProcent: number;
}

export const InspectionSummaryShowByRecord = {
  assignee: 'assignee',
  type: 'type',
} as const;

export type InspectionSummaryShowBy = keyof typeof InspectionSummaryShowByRecord;

export interface InspectionSummaryChart {
  readonly code: string;
  readonly label: string;
  value: number;
  backgroundColor: string;
}

export interface InspectionSummaryChartDTO {
  not_started: number;
  in_progress: number;
  late: number;
  completed: number;
  completed_late: number;
}
