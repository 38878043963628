import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  from,
  groupBy,
  of,
  startWith,
  switchMap,
  toArray,
} from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ComponentAbstract } from '@app/components/abstract/component.abstract';
import { procentCut } from '@app/utils/procent-cut';
import { loadAllUsers } from '@main-application/administration/store/actions/administration.actions';
import { selectAllUsers } from '@main-application/administration/store/selectors/administration.selectors';
import {
  InspectionSummaryExpandedTableData,
  InspectionSummaryModalData,
  InspectionSummaryStatsAssigneeDTO,
  InspectionSummaryStatsResponse,
  InspectionSummaryStatsTemplateDTO,
} from '@main-application/inspections/models/inspection-summary.model';
import { InspectionService } from '@main-application/inspections/services/inspection.service';
import { TimezoneService } from '@main-application/management/pages/system-configuration/components/date-time-configuration/timezone.service';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { getDate } from '@shared/functions/get-date.function';
import { ChartDataItem } from '@shared/interfaces/chart-data-item';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { downloadBlobFile } from '@shared/utils/download/download-blob';
import { debounceSafe$ } from '@shared/utils/rxjs/debounced-auto-save.rxjs.util';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';
import { CalendarHeaderComponent } from '@ui-components/components/calendar-header/calendar-header.component';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import {
  FILTER_PERIOD_RECORD,
  INSPECTION_SUMMARY_CHART,
  INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD,
  INSPECTION_SUMMARY_ERROR,
  SELECT_FILTER_PERIOD_CONFIG,
  SELECT_FILTER_SHOW_BY_CONFIG,
} from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.constant';
import {
  ISelectFilterConfig,
  InspectionSummaryShowBy,
  InspectionSummaryShowByRecord,
  InspectionSummaryTableDTO,
  InspectionsSummaryReportModalPeriod,
} from '@ui-components/modals/inspections-summary-report-modal/inspections-summary-report-modal.model';

@UntilDestroy()
@Component({
  selector: 'app-inspections-summary-report-modal',
  templateUrl: './inspections-summary-report-modal.component.html',
  styleUrls: ['./inspections-summary-report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionsSummaryReportModalComponent extends ComponentAbstract implements OnInit {
  readonly form = new FormGroup({
    groupByProperty: new FormControl<boolean>(false),
    showByType: new FormControl<ISelectFilterConfig>(SELECT_FILTER_SHOW_BY_CONFIG[1]),
    period: new FormControl<ISelectFilterConfig>(SELECT_FILTER_PERIOD_CONFIG[0]),
  });

  readonly selectPeriodFiltersConfig: ISelectFilterConfig[] = SELECT_FILTER_PERIOD_CONFIG;
  readonly selectShowByFiltersConfig: ISelectFilterConfig[] = SELECT_FILTER_SHOW_BY_CONFIG;

  readonly eIcon = EIcon;
  readonly eColorPalette = EColorPalette;
  readonly header = CalendarHeaderComponent;

  readonly today = this.timezoneService.getCurrentDate();
  activeStartDate = moment(this.today);
  activeEndDate = moment(this.today).add(1, 'days');

  readonly summaryReportChartData = new BehaviorSubject<ChartDataItem[] | null>(null);
  inspectionSummaryStatsFormattedChartData: {
    type: ChartDataItem[];
    assignee: ChartDataItem[];
  } = {
    type: [],
    assignee: [],
  };

  readonly summaryReportTableExpandedData = new BehaviorSubject<InspectionSummaryExpandedTableData[]>([]);
  inspectionSummaryStatsFormattedTableExpandedData: {
    type: InspectionSummaryExpandedTableData[];
    assignee: InspectionSummaryExpandedTableData[];
  } = {
    type: [],
    assignee: [],
  };

  readonly summaryReportTableData = new BehaviorSubject<InspectionSummaryTableDTO[]>([]);
  inspectionSummaryStatsFormattedTableData: {
    type: InspectionSummaryTableDTO[];
    assignee: InspectionSummaryTableDTO[];
  } = {
    type: [],
    assignee: [],
  };

  usersList: RestUserModel[] = [];

  dateRangeConfig = new DateRange<Date>(
    getDate(moment().format('MM/DD/YYYY')) || null,
    getDate(moment().format('MM/DD/YYYY')) || null
  );

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  isLoading = true;
  isDataLoaded = false;
  emptyDashboardMessage = INSPECTION_SUMMARY_ERROR.errorWrong;

  get groupByPropertyControl(): FormControl {
    return this.form.get('groupByProperty') as FormControl;
  }

  get showByTypeControl(): FormControl {
    return this.form.get('showByType') as FormControl;
  }

  get periodControl(): FormControl {
    return this.form.get('period') as FormControl;
  }

  constructor(
    private store: Store<{}>,
    protected readonly cdr: ChangeDetectorRef,
    private readonly snackbarService: SnackbarService,
    private readonly inspectionService: InspectionService,
    private readonly timezoneService: TimezoneService,
    private readonly dialogRef: MatDialogRef<InspectionsSummaryReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InspectionSummaryModalData
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.activateGroupByListener();

    this.loadSummaryReportData();
  }

  private loadSummaryReportData(): void {
    this.store.dispatch(loadAllUsers({}));

    this.store
      .select(selectAllUsers)
      .pipe(
        filterNullish$(),
        switchMap(userList => {
          this.usersList = userList;

          return this.loadTableData();
        }),
        filterNullish$(),
        untilDestroyed(this)
      )
      .subscribe({
        next: (response: InspectionSummaryStatsResponse) => {
          if (!response || (response?.sumByTemplate?.length === 0 && response?.sumByAssignee?.length === 0)) {
            this.emptyDashboardMessage = INSPECTION_SUMMARY_ERROR.errorNoData;

            this.isDataLoaded = false;
          } else {
            this.isDataLoaded = true;

            if (response.sumByTemplate) {
              this.setTableData(response.sumByTemplate, InspectionSummaryShowByRecord.type);
              this.setChartData(response.sumByTemplate, InspectionSummaryShowByRecord.type);
            }
            if (response.sumByAssignee) {
              this.setTableData(response.sumByAssignee, InspectionSummaryShowByRecord.assignee);
              this.setChartData(response.sumByAssignee, InspectionSummaryShowByRecord.assignee);
            }

            if (this.groupByPropertyControl.value) {
              if (response.sumByAssignee) {
                this.setTableExpandedData(response.sumByAssignee, InspectionSummaryShowByRecord.assignee);
              }
              if (response.sumByTemplate) {
                this.setTableExpandedData(response.sumByTemplate, InspectionSummaryShowByRecord.type);
              }
            }

            if (this.showByTypeControl.value?.id === 1) {
              this.summaryReportTableData.next(this.inspectionSummaryStatsFormattedTableData.assignee);
              this.summaryReportChartData.next(this.inspectionSummaryStatsFormattedChartData.assignee);
            } else {
              this.summaryReportTableData.next(this.inspectionSummaryStatsFormattedTableData.type);
              this.summaryReportChartData.next(this.inspectionSummaryStatsFormattedChartData.type);
            }
          }

          this.activateGroupByListener();

          this.isLoading = false;
          this.cdr.markForCheck();
        },
        error: (error: HttpErrorResponse) => {
          console.error('Failed to fetch summary stats:', error);

          this.snackbarService.error(`Failed to fetch summary stats: ${error.message}`);

          this.isDataLoaded = false;
          this.isLoading = false;
          this.cdr.markForCheck();
        },
      });
  }

  private loadTableData(): Observable<unknown> {
    return combineLatest([
      this.groupByPropertyControl.valueChanges.pipe(startWith(false)),
      this.periodControl.valueChanges.pipe(startWith(SELECT_FILTER_PERIOD_CONFIG[0])),
    ]).pipe(
      debounceSafe$(),
      switchMap(([groupByProperty, period]) => {
        const formattedPeriod = this.calcPeriod(period.label);

        return this.inspectionService.getSummaryStats({
          portfolioId: this.data.portfolioId,
          startDate: formattedPeriod.startDate,
          endDate: formattedPeriod.endDate,
          isResident: this.data.isResident,
          groupByProperty,
        });
      })
    );
  }

  private calcPeriod(period: string): InspectionsSummaryReportModalPeriod {
    let startDate = moment(this.today);
    let endDate = moment(this.today).add(1, 'days');

    switch (period) {
      case FILTER_PERIOD_RECORD.sevenDays:
        startDate = moment(this.today).subtract(7, 'days');
        break;

      case FILTER_PERIOD_RECORD.thirtyDays:
        startDate = moment(this.today).subtract(30, 'days');
        break;

      case FILTER_PERIOD_RECORD.threeMonths:
        startDate = moment(this.today).subtract(3, 'months');
        break;

      case FILTER_PERIOD_RECORD.sixMonths:
        startDate = moment(this.today).subtract(6, 'months');
        break;

      case FILTER_PERIOD_RECORD.year:
        startDate = moment(`${moment().year() - 1}-01-01T00:00:00.000Z`);
        endDate = moment(`${moment().year() - 1}-12-31T23:59:59.999Z`);
        break;

      case FILTER_PERIOD_RECORD.custom:
        this.trigger?.openMenu();
        break;

      case FILTER_PERIOD_RECORD.allTime:
        startDate = null;
        endDate = null;
        break;
    }

    if (period !== FILTER_PERIOD_RECORD.custom) {
      this.activeStartDate = startDate;
      this.activeEndDate = endDate;

      return {
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate?.toISOString() : null,
      };
    }
    return { startDate: this.activeStartDate.toISOString(), endDate: this.activeEndDate.toISOString() };
  }

  private setTableData(tableData: Partial<InspectionSummaryStatsAssigneeDTO>[], showBy: InspectionSummaryShowBy): void {
    const mappedTableData = [];
    tableData.forEach(s => {
      const completedProcent = procentCut((s.completed / s.total) * 100);
      const completedLateProcent = procentCut((s.completedLate / s.total) * 100);

      const item = {
        template:
          showBy === InspectionSummaryShowByRecord.assignee
            ? this.getAssigneeNameFromUsers(s.assignee)
            : s.inspectionName,
        not_started: s.notStarted,
        in_progress: s.inProgress,
        late: s.late,
        expired: s.expired,
        completed: s.completed,
        completedProcent: completedProcent,
        completed_late: s.completedLate,
        completedLateProcent: completedLateProcent,
        total: s.total,
      };

      mappedTableData.push(item);
    });

    if (showBy === InspectionSummaryShowByRecord.type) {
      this.inspectionSummaryStatsFormattedTableData.type = [...mappedTableData];
    } else {
      this.inspectionSummaryStatsFormattedTableData.assignee = [...mappedTableData];
    }
  }

  private setTableExpandedData(
    tableData: Partial<InspectionSummaryStatsAssigneeDTO>[],
    showBy: InspectionSummaryShowBy
  ): void {
    from(tableData)
      .pipe(
        filterNullish$(),
        groupBy(inspection => inspection.propertyId),
        mergeMap(group => group.pipe(toArray())),
        toArray(),
        untilDestroyed(this)
      )
      .subscribe({
        next: (value: Array<InspectionSummaryStatsAssigneeDTO[]>) => {
          const expandedTableData: InspectionSummaryExpandedTableData[] = value
            .reduce(
              (accumValue: InspectionSummaryExpandedTableData[], currentValue: InspectionSummaryStatsAssigneeDTO[]) => {
                const formattedValue = currentValue.reduce(
                  (acc, cur, i) => {
                    if (i === 0) {
                      acc.expandedTitle = cur.propertyName;
                      acc.propertyId = cur.propertyId;
                    }
                    acc.total.notStarted += cur.notStarted;
                    acc.total.inProgress += cur.inProgress;
                    acc.total.late += cur.late;
                    acc.total.completed += cur.completed;
                    acc.total.completedLate += cur.completedLate;
                    acc.total.expired += cur.expired;
                    acc.total.total += cur.total;

                    cur.inspectionName =
                      showBy === InspectionSummaryShowByRecord.type
                        ? cur.inspectionName
                        : this.getAssigneeNameFromUsers(cur.assignee);

                    acc.data.push(cur);

                    return acc as InspectionSummaryExpandedTableData;
                  },
                  {
                    expandedTitle: '',
                    propertyId: 0,
                    total: {
                      notStarted: 0,
                      inProgress: 0,
                      late: 0,
                      completed: 0,
                      completedLate: 0,
                      expired: 0,
                      total: 0,
                    },
                    data: [],
                  }
                );

                accumValue.push(formattedValue);

                return accumValue;
              },
              []
            )
            .sort((a, b) => a.expandedTitle.localeCompare(b.expandedTitle));

          if (showBy === InspectionSummaryShowByRecord.type) {
            this.inspectionSummaryStatsFormattedTableExpandedData.type = [...expandedTableData];
          } else {
            this.inspectionSummaryStatsFormattedTableExpandedData.assignee = [...expandedTableData];
          }

          if (this.showByTypeControl.value.id === 1) {
            this.summaryReportTableExpandedData.next(this.inspectionSummaryStatsFormattedTableExpandedData.assignee);
          } else {
            this.summaryReportTableExpandedData.next(this.inspectionSummaryStatsFormattedTableExpandedData.type);
          }
        },
      });
  }

  private setChartData(tableData: InspectionSummaryStatsTemplateDTO[], showBy: InspectionSummaryShowBy): void {
    const chartData = tableData.reduce(
      (acc, curr) => {
        acc.completed += curr.completed;
        acc.completed_late += curr.completedLate;
        acc.expired += curr.expired;
        acc.in_progress += curr.inProgress;
        acc.late += curr.late;
        acc.not_started += curr.notStarted;
        return acc;
      },
      { in_progress: 0, not_started: 0, completed: 0, completed_late: 0, expired: 0, late: 0 }
    );

    const maxChartValue = Math.max(
      ...INSPECTION_SUMMARY_CHART.map((_, index) => chartData[INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD[index]] || 0)
    );

    const formattedData = INSPECTION_SUMMARY_CHART.map((item, index) => ({
      ...item,
      value: chartData[INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD[index]] || 0,
      backgroundColor:
        (chartData[INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD[index]] || 0) === maxChartValue
          ? this.eColorPalette.cGreen3
          : (chartData[INSPECTION_SUMMARY_CHAR_LABELS__KEYS_RECORD[index]] || 0) > 0
          ? this.eColorPalette.cGreen11
          : this.eColorPalette.cGray8,
    }));

    if (showBy === InspectionSummaryShowByRecord.type) {
      this.inspectionSummaryStatsFormattedChartData.type = formattedData;
    } else {
      this.inspectionSummaryStatsFormattedChartData.assignee = formattedData;
    }
  }

  private getAssigneeNameFromUsers(id: number): string {
    return this.usersList.find(a => a.id === id)?.displayName || 'Unassigned';
  }

  selectedPeriod(event: ISelectFilterConfig): void {
    if (event.id === 7) {
      this.trigger?.openMenu();
    } else {
      this.periodControl.patchValue(event);
    }
  }

  selectedShowBy(event: ISelectFilterConfig): void {
    if (event.id === 1) {
      this.showByTypeControl.patchValue(SELECT_FILTER_SHOW_BY_CONFIG[0]);

      this.summaryReportTableData.next(this.inspectionSummaryStatsFormattedTableData.assignee);
      this.summaryReportChartData.next(this.inspectionSummaryStatsFormattedChartData.assignee);
    } else {
      this.showByTypeControl.patchValue(SELECT_FILTER_SHOW_BY_CONFIG[1]);

      this.summaryReportTableData.next(this.inspectionSummaryStatsFormattedTableData.type);
      this.summaryReportChartData.next(this.inspectionSummaryStatsFormattedChartData.type);
    }

    this.isLoading = false;
  }

  activateGroupByListener(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(({ groupByProperty, showByType }) => {
      this.isLoading = true;

      if (groupByProperty) {
        if (showByType.label.toLowerCase() === InspectionSummaryShowByRecord.assignee) {
          this.summaryReportTableExpandedData.next(this.inspectionSummaryStatsFormattedTableExpandedData.assignee);
        } else {
          this.summaryReportTableExpandedData.next(this.inspectionSummaryStatsFormattedTableExpandedData.type);
        }
      }
    });
  }

  onDownloadXlsFile(): void {
    this.inspectionService
      .onDownloadInspectionSummary({
        portfolioId: this.data.portfolioId,
        startDate: this.activeStartDate.toISOString(),
        endDate: this.activeEndDate.toISOString(),
        isResident: this.data.isResident,
        groupByProperty: this.groupByPropertyControl.value,
      })
      .pipe(
        catchError(() => of(null)),
        untilDestroyed(this)
      )
      .subscribe({
        next: blob =>
          blob
            ? downloadBlobFile(blob, 'inspection-summary.xlsx')
            : this.snackbarService.error(SnackbarErrorMessage.ErrorFileDownload),
      });
  }

  onSelect(date: Date) {
    if (this.dateRangeConfig?.start && date > this.dateRangeConfig.start && !this.dateRangeConfig.end) {
      this.dateRangeConfig = new DateRange(this.dateRangeConfig.start, date);
      this.activeStartDate = moment(this.dateRangeConfig.start);
      this.activeEndDate = moment(this.dateRangeConfig.end);

      this.trigger.closeMenu();

      this.periodControl.patchValue(SELECT_FILTER_PERIOD_CONFIG[6]);
    } else {
      this.dateRangeConfig = new DateRange(date, null);
    }
  }
}
