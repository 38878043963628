import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { PendingService } from '../services/pending.service';

@Injectable({
  providedIn: 'root',
})
export class HttpPendingInterceptor implements HttpInterceptor {
  constructor(private pendingService: PendingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.pendingService.setLoading(true, request.url);

    return next.handle(request).pipe(
      tap((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.pendingService.setLoading(false, request.url);
        }
      }),
      catchError(err => {
        this.pendingService.setLoading(false, request.url);
        return throwError(() => err);
      }),
      finalize(() => this.pendingService.clearPending(request.url))
    );
  }
}
